import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("samyojak");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});
export const getAllPlantdata = createAsyncThunk(
    "Plants/getAllPlantdata",
    async () => {
        try {
            const response = await BaseUrl.get(`plant`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getPlantListdata = createAsyncThunk(
    "Plants/getPlantListdata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`plant/plantslist/${id}`, getAuthHeaders());
            // console.log("plantList==>", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getSinglePlantdata = createAsyncThunk(
    "Plants/getSinglePlantdata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`plant/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getDashboardPlantdata = createAsyncThunk(
    "Plants/getDashboardPlantdata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`plant/plantdata/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getOilVenderAndManufactureDashboard = createAsyncThunk(
    "Plants/getOilVenderAndManufactureDashboard",
    async (id) => {
        try {
            const response = await BaseUrl.get(`machine/oil-vendor-and-manufacture-plants/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const addPlantdata = createAsyncThunk(
    "Plants/addPlantdata",
    async (data) => {
        try {
            const response = await BaseUrl.post(`plant`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const updatePlantdata = createAsyncThunk(
    "Plants/updatePlantdata",
    async (data) => {
        try {
            const response = await BaseUrl.patch(`plant`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

// login SLICE
export const loginSlice = createSlice({
    name: "plant",
    initialState: {
        PlantData: [],
        plantList: [],
        noOfPlans: 0,
        company_name: '',
        dashboardPlantData: [],
        oilVAndMPlantashboard: [],
        singlePlantData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllPlantdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllPlantdata.fulfilled, (state, action) => {
                state.loading = false;
                state.PlantData = action.payload;
            })
            .addCase(getAllPlantdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getPlantListdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPlantListdata.fulfilled, (state, action) => {
                state.loading = false;
                state.plantList = action.payload;
            })
            .addCase(getPlantListdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getSinglePlantdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSinglePlantdata.fulfilled, (state, action) => {
                state.loading = false;
                state.singlePlantData = action.payload;
            })
            .addCase(getSinglePlantdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getDashboardPlantdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDashboardPlantdata.fulfilled, (state, action) => {
                state.loading = false;
                state.noOfPlans = action.payload.noOfRecords;
                state.company_name = action.payload.company_name;
                state.dashboardPlantData = action.payload.data;
            })
            .addCase(getDashboardPlantdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getOilVenderAndManufactureDashboard.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOilVenderAndManufactureDashboard.fulfilled, (state, action) => {
                state.loading = false;
                state.company_name = action.payload.company_name;
                state.oilVAndMPlantashboard = action.payload.data;
            })
            .addCase(getOilVenderAndManufactureDashboard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});

// SELECTORS
export const getnoOfPlans = (state) => state.plant.noOfPlans;
export const getPlantsData = (state) => state.plant.PlantData;
export const getDashboardPlant = (state) => state.plant.dashboardPlantData;
export const getOilVAndMPlantashboard = (state) => state.plant.oilVAndMPlantashboard;
export const getPlantCompany = (state) => state.plant.company_name;
export const plantListsData = (state) => state.plant.plantList;
export const getSinglePlantsData = (state) => state.plant.singlePlantData;
export const PlantError = (state) => state.plant.error;

// EXPORTS
export default loginSlice.reducer;