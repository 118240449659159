import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UseAuth } from '../customhooks/UseAuth'
import { headerBar } from '../GlobalTitles';

const TopBarComponent = () => {
const [title,setTitle]=useState({})
    const { roleId, name } = UseAuth();
    const styles = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItem: "center",
        position: "sticky",
        top: "0px",
        zIndex: 99,
        boxSadhow: "5px 0 10px black",
        p: 1
    }

    useEffect(() => {
        const headerBarTitle = headerBar()
        setTitle(headerBarTitle)
    }, [])
    return (
        <>
            <Box sx={styles} bgcolor={"white"} borderBottom={"2px lightgrey solid"}>
                {/* <Avatar style={{ width: 60, height: 60, boxShadow: "0 0 5px black" }} src='/iot_logo.jpeg' alt='samyojak_logo.jpeg' /> */}
                <Typography variant='h6'>
                    {`${title.headerTitle}`}
                </Typography>
                <Typography variant='body'>
                    {name}&nbsp;&nbsp;&nbsp;
                    {roleId === 2000 ? "Super Admin" : roleId === 3000 ? "Company Admin" : roleId === 4000 ? "Plant Admin" : roleId === 5000 ? "Machine Admin" : roleId === 6000 ? "Oil Vendor" : roleId === 7000 ? "Oil Manufacturer" : ""}
                </Typography>
            </Box>
        </>
    )
}

export default TopBarComponent