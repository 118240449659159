import { ArrowBackIosNewOutlined, Dashboard, Groups, LocationCityOutlined, LoginOutlined, MenuOutlined, Notifications, OnDeviceTraining, PrecisionManufacturing, Settings, Summarize, VaccinesOutlined } from '@mui/icons-material'
import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar'
import { useLocation, useNavigate } from 'react-router-dom'
import { UseAuth } from '../customhooks/UseAuth'
import { sideBarTitles } from '../GlobalTitles'

const Items = ({ label, icon, color, onClickAction, active }) => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <Tooltip title={label} placement="right-start">
            <div>
                <MenuItem
                    active={active}
                    style={{
                        // color: color,
                        // background: active ? '#f0f0f0' : (isHovered ? '#f0f0f0' : 'white'),// Change background color on hover
                        color: active ? '#43E2B7' : (isHovered ? '#43E2B7' : 'black'),
                        borderStartStartRadius: 20,
                        borderEndStartRadius: 20,
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    icon={icon}
                    onClick={onClickAction}
                >
                    <Typography>{label}</Typography>
                </MenuItem>
            </div>
        </Tooltip>
    )
}

const SideBarComponent = () => {
    const { roleId } = UseAuth();
    const [show, setHide] = useState(false)
    const [activeItem, setActiveItem] = useState('');
    const navigate = useNavigate()
    const location = useLocation();

    const globalTitles = sideBarTitles()

    const handleNavigation = (path) => {
        navigate(path)
        setActiveItem(path); // Update the active item when navigating
    }

    useEffect(() => {
        // Extract the pathname from location object and set the active item accordingly
        setActiveItem(location.pathname);
    }, [location]);

    const logOutHandler = () => {
        navigate('/')
        localStorage.clear('samyojak')
    }
    return (
        <>
            <Box>
                <Sidebar collapsed={show}
                    transitionDuration={500}
                    backgroundColor={"white"}
                    rootStyles={{
                        height: "100vh",
                        boxShadow: "0 3px 3em #f0f0f0"
                    }}>
                    {!show && <Box sx={{ textAlign: "right", p: 1.5, cursor: "pointer", position: "sticky", top: "0em", bgcolor: "white", zIndex: 99 }}>
                        <ArrowBackIosNewOutlined onClick={() => setHide(true)} />
                    </Box>}
                    {show && <Box sx={{ textAlign: "center", p: 1.5, cursor: "pointer", position: "sticky", top: "0em", bgcolor: "white", zIndex: 99 }}>
                        <MenuOutlined onClick={() => setHide(false)} />
                    </Box>}
                    {!show && <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1em", p: 1, position: "sticky", top: "3em", zIndex: 99, bgcolor: "white" }}>
                        <Avatar style={{ width: 70, height: 70, marginTop: "-2em", boxShadow: "0 0 5px black" }} src='/iot_logo.jpeg' alt='N' />
                        {/* <img src="/logo_2.jpeg" alt="logo_2.jpeg" style={{ width: "auto", height: 70, marginTop: "0.5em", border: "2px lightgrey solid", padding: "0 0.3em" }} /> */}
                    </Box>}
                    <Menu
                        closeOnClick
                        transitionDuration={500}
                    >
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (<Items label={`${globalTitles?.dashboardName}`} icon={<Dashboard size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot')} active={activeItem === '/iot'} />
                        )}
                        {(roleId === 6000 || roleId === 7000) && (<Items label={`${globalTitles?.dashboardName}`} icon={<Dashboard size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot/oil-vendor-manufacture-company-dashboard')} active={activeItem === '/iot/oil-vendor-manufacture-company-dashboard'} />
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <Items label={`${globalTitles?.manageUserName}`} icon={<Groups size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot/users')} active={activeItem === '/iot/users'} />
                        )}
                        {/* <SubMenu icon={<SettingsInputComponentOutlined />} label="Manage Customers"> */}
                        {/* <Items label={'Super Admin'} icon={<LocationCityOutlined size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot/companyadmin')} active={activeItem === '/iot/companyadmin'} /> */}
                        {/* <Items label={'Machine Admin'} icon={<Yard size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot/machineadmin')} />
                        {/* </SubMenu> */}

                        {(roleId === 6000) && (
                            <Items label={`${globalTitles?.vendorName}`} icon={<PrecisionManufacturing size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot/oilvender')} active={activeItem === '/iot/oilvender'} />
                        )}
                        {/* <Items label={'Machine Admin'} icon={<Yard size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot/machineadmin')} /> */}


                        {(roleId === 7000) && (
                            <Items label={`${globalTitles?.manufactureName}`} icon={<VaccinesOutlined size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot/oilmanufacturer')} active={activeItem === '/iot/oilmanufacturer'} />
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <SubMenu icon={<LocationCityOutlined />} label="Manage Companies">
                                <Box padding={"0 0 0 1.5em"}>
                                    {(roleId === 2000 || roleId === 3000) && (
                                        <Items label={`${globalTitles?.manageCompanyName}`}
                                            // icon={<LocationCityOutlined size={25} />} 
                                            color={"black"} onClickAction={() => handleNavigation('/iot/managecomapny')} active={activeItem === '/iot/managecomapny'} />
                                    )}
                                    {(roleId === 2000 || roleId === 3000 || roleId === 4000) && (
                                        <Items label={`${globalTitles?.managePlantName}`}
                                            // icon={<Yard size={25} />}
                                            color={"black"} onClickAction={() => handleNavigation('/iot/manageplant')} active={activeItem === '/iot/manageplant'} />
                                    )}
                                    {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                                        <Items label={`${globalTitles?.manageMachineName}`}
                                            // icon={<PrecisionManufacturing size={25} />}
                                            color={"black"} onClickAction={() => handleNavigation('/iot/managemachine')} active={activeItem === '/iot/managemachine'} />
                                    )}
                                    <Items label={`${globalTitles?.manageDeviceName}`}
                                        //  icon={<VaccinesOutlined size={25} />} 
                                        color={"black"} onClickAction={() => handleNavigation('/iot/managedevice')} active={activeItem === '/iot/managedevice'} />
                                </Box>
                            </SubMenu>
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <SubMenu icon={<OnDeviceTraining />} label={`${globalTitles?.subManageDevice}`}>
                                <Box padding={"0 0 0 1.5em"}>
                                    <Items label={`${globalTitles?.subManageDeviceRawData}`}
                                        //  icon={<VaccinesOutlined size={25} />} 
                                        color={"black"} onClickAction={() => handleNavigation('/iot/devicerawdata')} active={activeItem === '/iot/devicerawdata'} />
                                    <Items label={`${globalTitles?.subManageDeviceProccessData}`}
                                        //  icon={<VaccinesOutlined size={25} />} 
                                        color={"black"} onClickAction={() => handleNavigation('/iot/deviceproccessseddata')} active={activeItem === '/iot/deviceproccessseddata'} />
                                </Box>
                            </SubMenu>
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <SubMenu icon={<Summarize />} label={`${globalTitles?.subManageReport}`}>
                                <Box padding={"0 0 0 1.5em"}>
                                    <Items label={`${globalTitles?.subManageReportSummery}`}
                                        // icon={<Assignment size={25} />} 
                                        color={"black"} onClickAction={() => handleNavigation('/iot/reports')} active={activeItem === '/iot/reports'} />
                                    <Items label={`${globalTitles?.subManageReportDetailed}`}
                                        //  icon={<Assignment size={25} />}
                                        color={"black"} onClickAction={() => handleNavigation('/iot/detailedreports')} active={activeItem === '/iot/detailedreports'} />
                                </Box>
                            </SubMenu>
                        )}
                        {/* {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <Items label={'Notification'} icon={<Notifications size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot/notifications')} active={activeItem === '/iot/notifications'} />
                        )}
                        {(roleId === 2000 || roleId === 3000 || roleId === 4000 || roleId === 5000) && (
                            <Items label={'Settings'} icon={<Settings size={25} />} color={"black"} onClickAction={() => handleNavigation('/iot/settings')} active={activeItem === '/iot/settings'} />
                        )} */}
                        <Items label={`${globalTitles?.logOut}`} icon={<LoginOutlined size={25} />} color={"black"} onClickAction={() => logOutHandler()} />
                    </Menu>
                </Sidebar>
            </Box>
        </>
    )
}

export default SideBarComponent