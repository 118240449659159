import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import SideBarComponent from './components/SideBarComponent';
import TopBarComponent from './components/TopBarComponent';
import { Suspense, lazy } from 'react';
const LoginComponent = lazy(() => import('./components/login/LoginComponent'));

const DashBoardComponent = lazy(() => import('./components/dashboard/DashBoardComponent'));
const PlantDashboardComponent = lazy(() => import('./components/dashboard/PlantDashboard'));
const MachineDashboardComponent = lazy(() => import('./components/dashboard/MachineDashboard'));
const OilVanderAndManufacturerCompanyDashboard = lazy(() => import('./components/dashboard/OilVendorAndManufactureCompanyDashboard'));
const OilVanderAndManufacturerPlantDashboard = lazy(() => import('./components/dashboard/OilVenderAndManufacturePlantdashboard'));
const OilVanderAndManufacturerMachineDashboard = lazy(() => import('./components/dashboard/OilVanderAndManufacturerDashboard'));
const DeviceDashboardComponent = lazy(() => import('./components/dashboard/DeviceDashboard'));
const DeviceStatusDashboardComponent = lazy(() => import('./components/dashboard/DeviceStatusDashboard'));



const CompanyAdminComponent = lazy(() => import('./components/manageUsers/CompanyAdmin'));
const AddCompanyAdmin = lazy(() => import('./components/manageUsers/AddCompanyAdmin'));
const UpdateCompanyAdmin = lazy(() => import('./components/manageUsers/UpdateCompanyAdmin'));

const MachinAdminComponent = lazy(() => import('./components/machinAdmin/MachinAdmin'));

const OilVenderComponent = lazy(() => import('./components/oilVender/OilVender'));
const OilVendorViewComponent = lazy(() => import('./components/oilVender/OilVendorView'));


const OilManufacturerComponent = lazy(() => import('./components/oilManufacturer/OilManufacturer'));

const ManageComapanyComponent = lazy(() => import('./components/manageCompany/ManageComapany'));
const AddCompanyComponent = lazy(() => import('./components/manageCompany/AddCompany'));
const UpdateCompanyComponent = lazy(() => import('./components/manageCompany/UpdateCompany'));

const ManagePlantsComponent = lazy(() => import('./components/managePlants/ManagePlants'));
const AddPlantComponent = lazy(() => import('./components/managePlants/AddPlant'));
const UpdatePlantComponent = lazy(() => import('./components/managePlants/UpdatePlant'));

const ManageMachineComponent = lazy(() => import('./components/manageMachine/ManageMachine'));
const AddMachineComponent = lazy(() => import('./components/manageMachine/AddMachine'));
const UpdateMachineComponent = lazy(() => import('./components/manageMachine/UpdateMachine'));

const ManageDeviceComponent = lazy(() => import('./components/manageDevice/ManageDevice'));
const AddDeviceComponent = lazy(() => import('./components/manageDevice/AddDevice'));
const UpdateDeviceComponent = lazy(() => import('./components/manageDevice/UpdateDevice'));

const DeviceRawDataComponent = lazy(() => import('./components/deviceRawData/DeviceRawData'));
const DeviceProccessedDataComponent = lazy(() => import('./components/deviceProccessedData/DeviceProccessedData'));


const ReportsComponent = lazy(() => import('./components/reports/Reports'));
const DetailedReportComponent = lazy(() => import('./components/reports/DetailedReport'));

const NotificationsComponent = lazy(() => import('./components/notifications/NotificationsComponent'));

const SettingsComponent = lazy(() => import('./components/settings/Settings'));

function App() {

  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  return (
    <>
      <div className="app">
        {!isLoginPage && <SideBarComponent />}
        <main className='content'>
          {!isLoginPage && <TopBarComponent />}
          <Routes>
            <Route path='/'>
              <Route index element={<Suspense fallback={<div>Loading..</div>}><LoginComponent /></Suspense>} />
            </Route>
            <Route path='/iot'>
              <Route index element={<Suspense fallback={<div>Loading..</div>}><DashBoardComponent /></Suspense>} />
              <Route path='plants-dashboard/:cid' element={<Suspense fallback={<div>Loading..</div>}><PlantDashboardComponent /></Suspense>} />
              <Route path='oil-vendor-manufacture-company-dashboard' element={<Suspense fallback={<div>Loading..</div>}><OilVanderAndManufacturerCompanyDashboard /></Suspense>} />
              <Route path='oil-vendor-manufacture-plant-dashboard/:cid' element={<Suspense fallback={<div>Loading..</div>}><OilVanderAndManufacturerPlantDashboard /></Suspense>} />
              <Route path='oil-vendor-manufacture-machine-dashboard/:pid' element={<Suspense fallback={<div>Loading..</div>}><OilVanderAndManufacturerMachineDashboard /></Suspense>} />
              <Route path='machine-dashboard/:pid' element={<Suspense fallback={<div>Loading..</div>}><MachineDashboardComponent /></Suspense>} />
              <Route path='device-dashboard/:mid' element={<Suspense fallback={<div>Loading..</div>}><DeviceDashboardComponent /></Suspense>} />
              <Route path='device-status/:mid' element={<Suspense fallback={<div>Loading..</div>}><DeviceStatusDashboardComponent /></Suspense>} />

              <Route path='users' element={<Suspense fallback={<div>Loading..</div>}><CompanyAdminComponent /></Suspense>} />
              <Route path='addusers' element={<Suspense fallback={<div>Loading..</div>}><AddCompanyAdmin /></Suspense>} />
              <Route path='updateusers/:cid' element={<Suspense fallback={<div>Loading..</div>}><UpdateCompanyAdmin /></Suspense>} />
              <Route path='machineadmin' element={<Suspense fallback={<div>Loading..</div>}><MachinAdminComponent /></Suspense>} />


              <Route path='oilvender' element={<Suspense fallback={<div>Loading..</div>}><OilVenderComponent /></Suspense>} />
              <Route path='oilvender/:ovid' element={<Suspense fallback={<div>Loading..</div>}><OilVendorViewComponent /></Suspense>} />


              <Route path='oilmanufacturer' element={<Suspense fallback={<div>Loading..</div>}><OilManufacturerComponent /></Suspense>} />

              <Route path='managecomapny' element={<Suspense fallback={<div>Loading..</div>}><ManageComapanyComponent /></Suspense>} />
              <Route path='addcomapny' element={<Suspense fallback={<div>Loading..</div>}><AddCompanyComponent /></Suspense>} />
              <Route path='updatecomapny/:cid' element={<Suspense fallback={<div>Loading..</div>}><UpdateCompanyComponent /></Suspense>} />

              <Route path='manageplant' element={<Suspense fallback={<div>Loading..</div>}><ManagePlantsComponent /></Suspense>} />
              <Route path='addplant' element={<Suspense fallback={<div>Loading..</div>}><AddPlantComponent /></Suspense>} />
              <Route path='updateplant/:pid' element={<Suspense fallback={<div>Loading..</div>}><UpdatePlantComponent /></Suspense>} />

              <Route path='managemachine' element={<Suspense fallback={<div>Loading..</div>}><ManageMachineComponent /></Suspense>} />
              <Route path='addmachine' element={<Suspense fallback={<div>Loading..</div>}><AddMachineComponent /></Suspense>} />
              <Route path='updatemachine/:mid' element={<Suspense fallback={<div>Loading..</div>}><UpdateMachineComponent /></Suspense>} />

              <Route path='managedevice' element={<Suspense fallback={<div>Loading..</div>}><ManageDeviceComponent /></Suspense>} />
              <Route path='adddevice' element={<Suspense fallback={<div>Loading..</div>}><AddDeviceComponent /></Suspense>} />
              <Route path='updatedevice/:did' element={<Suspense fallback={<div>Loading..</div>}><UpdateDeviceComponent /></Suspense>} />

              <Route path='devicerawdata' element={<Suspense fallback={<div>Loading..</div>}><DeviceRawDataComponent /></Suspense>} />
              <Route path='deviceproccessseddata' element={<Suspense fallback={<div>Loading..</div>}><DeviceProccessedDataComponent /></Suspense>} />


              <Route path='reports' element={<Suspense fallback={<div>Loading..</div>}><ReportsComponent /></Suspense>} />
              <Route path='detailedreports' element={<Suspense fallback={<div>Loading..</div>}><DetailedReportComponent /></Suspense>} />


              <Route path='notifications' element={<Suspense fallback={<div>Loading..</div>}><NotificationsComponent /></Suspense>} />

              <Route path='settings' element={<Suspense fallback={<div>Loading..</div>}><SettingsComponent /></Suspense>} />
            </Route>
            <Route path='*' element={<div>Page Not Found..</div>} />
          </Routes>
        </main>
      </div>
    </>
  );
}

export default App;
