export const headerBar = () => {
    let headTtile = {
        headerTitle: "Welcome to IOT"
    }
    return headTtile
}

export const mainTitles = () => {
    let mainLabels = {
        user: "User",
        company: "Company",
        plant: "Plant",
        machine: "Machine",
        device: "Device",
    }
    return mainLabels
}

export const sideBarTitles = () => {
    let sideBarItemTitles = {
        dashboardName: "Dashboard",
        manageUserName: "Manage user",
        manageCompanyName: "Manage Company",
        managePlantName: "Manage Plant",
        manageMachineName: "Manage Machine",
        manageDeviceName: "Manage Device",
        vendorName: "Oil Vendor",
        manufactureName: "Oil Manufaturer",
        subManageCompanies: "Manage Companies",
        subManageDevice: "Manage Device Data",
        subManageDeviceRawData: "Device Raw Data",
        subManageDeviceProccessData: "Device Proccess Data",
        subManageReport: "Manage Reports",
        subManageReportSummery: "Summery Reports",
        subManageReportDetailed: "Detailed Reports",
        logOut: "Log Out",
    }
    return sideBarItemTitles
}

export const dashboardHeadlines = () => {
    let dashboardTitle = {
        companyDashboard: "Company Dashboard",
        plantDashboard: "Plant Dashboard",
        machineDashboard: "Machine Dashboard",
        deviceDashboard: "Device Dashboard",
        DeviceStatusDashboard: "Device Status Dashboard",
        deviceRawDataDashboard: "Device Raw Data",
        deviceProccessDataDashboard: "Device Proccess Data",
        deviceSummeryReportDataDashboard: "Device Summery Report Data",
        deviceDetailedReportDataDashboard: "Device Detailed Report Data",
        user: "Users",
        company: "Companies",
        plant: "Plants",
        machine: "Machines",
        device: "Devices",
        deviceStatusMachine: "Machine",
        deviceStatusOil: "Oil",
        deviceStatusDevice: "Device",
        manageUser: "Manage Users",
        createUser: "Create New User",
        updateUser: "Update New User",
        manageCompany: "Manage Companys",
        createCompany: "Create New Company",
        updateCompany: "Update New Company",
        managePlant: "Manage Plants",
        createPlant: "Create New Plant",
        updatePlant: "Update New Plant",
        manageMachine: "Manage Machines",
        createMachine: "Create New Machine",
        updateMachine: "Update New Machine",
        manageDevice: "Manage Devices",
        createDevice: "Create New Device",
        updateDevice: "Update New Device",
        deviceRawPage: "Device Raw Data",
        deviceProccessPage: "Device Process Data",
        summeryReportPage: "Summary Report Data",
        detailedReportPage: "Detailed Report Data",
    }
    return dashboardTitle
}

export const inputLabels = () => {
    let labels = {
        userId: "Id",
        userName: "User Name",
        useremail: "Email Address",
        userPhoneNumber: "Phone Number",
        userPassword: "Password",
        userRole: "Role",
        userDestination: "Destination",
        userDepartment: "Department",
        userOilCompanyName: "Oil Company Name",
        companyId: "Id",
        companyName: "Company Name",
        companyAdminId: "Company Admin Id",
        companyAdminName: "Company Admin Name",
        companyAdminContactNumber: "Company Admin Contact Number",
        companyWebsite: "Website Name",
        companyAddress: "Company Address",
        companyLogo: "Company Logo",
        plantId: "Id",
        plantName: "Plant Name",
        plantAdminId: "Plant Admin Id",
        plantAdminName: "Plant Admin Name",
        plantAdminContactNumber: "Plat Admin Contact Number",
        plantAddress: "Plant Address",
        plantLogo: "Plant Logo",
        machineId: "Id",
        machineName: "Machine Name",
        machineSectionName: "Section Name",
        machineType: "Machine Type",
        machineManufacturer: "Machine Manufacturer",
        machineModelNumber: "Model Number",
        machinePartNumber: "Part Number",
        machineManufacturerAdminId: "Manufacturer Admin Id",
        machineManufacturerAdminName: "Manufacturer Admin Name",
        machineManufacturerAdminContactNumber: "Manufacturer Admin Contact Number",
        machineVendorAdminId: "Vendor Admin Id",
        machineVendorAdminName: "Vendor Admin Name",
        machineVendorAdminContactNumber: "Vendor Admin Contact Number",
        machineVendorDataPublish: "Oil Vendor Data Publish",
        machineAdminId: "Machine Admin Id",
        machineAdminName: "Machine Admin Name",
        machineAdminContactNumber: "Machine Admin Contact Number",
        machineOilTankCapacity: "Oil Tank Capacity",
        machineFullOilLevel: "Full Oil Level",
        machineReserveOilLevel: "Reserve Oil Level",
        machineOilType: "Oil Type",
        machineConcentrationLowerLevel: "Concentration Lower Level",
        machineConcentrationUpperLevel: "Concentration Upper Level",
        machinePhLowerLevel: "Ph Lower Level",
        machinePhUpperLevel: "Ph Upper Level",
        machineTemperatureLowerLevel: "Temperature Lower Level",
        machineTemperatureUpperLevel: "Temperature Upper Level",
        machineLogo: "Machine Logo",
        deviceId: "Id",
        deviceName: "Device Name",
        deviceNumber: "Device Number",
        deviceVersion: "Device Version",
        deviceType: "Device Type",
        deviceToken: "Device Token",
        summeryDate: "Date",
        summeryFromDate: "From Date",
        summeryToDate: "To Date",
        deviceRawDataId: "Raw data Id",
        deviceRawDataCompanyId: "Company Id",
        deviceRawDataPlantId: "Plant Id",
        deviceRawDataMachineId: "Machine Id",
        deviceRawDataDeviceId: "Device Id",
        deviceRawDataDateAndTime: "Date And Time",
        deviceRawDataConcentration1: "Concentration 1",
        deviceRawDataConcentration2: "Concentration 2",
        deviceRawDataConcentration3: "Concentration 3",
        deviceRawDataConcentration4: "Concentration 4",
        deviceRawDataTemperature: "Temperature",
        deviceRawDataPh: "Ph",
        deviceRawDataSpectralDataA: "Spectral Data a",
        deviceRawDataSpectralDataB: "Spectral Data b",
        deviceRawDataSpectralDataC: "Spectral Data c",
        deviceRawDataSpectralDataD: "Spectral Data d",
        deviceRawDataSpectralDataE: "Spectral Data e",
        deviceRawDataSpectralDataF: "Spectral Data f",
        deviceRawDataSpectralDataG: "Spectral Data g",
        deviceRawDataSpectralDataH: "Spectral Data h",
        deviceRawDataSpectralDataI: "Spectral Data i",
        deviceRawDataLevel: "Level",
    }
    return labels
}

export const dropDownLabels = () => {
    let dropLabels = {
        roleDropDown: "Select Role*",
        companyDropDown: "Select Company*",
        companyAdminDropDown: "Select Company Admin*",
        plantDropDown: "Select Plant*",
        plantAdminDropDown: "Select Plant Admin*",
        machineDropDown: "Select Machine*",
        machineAdminDropDown: "Select Machine Admin*",
        oilManufacturerAdminDropDown: "Select Oil Manufacturer Admin*",
        oilVendorAdminDropDown: "Select Oil Vendor Admin*",
        deviceTypeDropDown: "Select Device Type*",
    }
    return dropLabels
}

export const checkBoxLabels = () => {
    let checkLabels = {
        oilVendorDataPublish: "Oil Vendor Data Publish",
        oilManufaturerDataPublish: "Oil Manufaturer Data Publish",
    }
    return checkLabels
}