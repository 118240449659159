import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("samyojak");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});
export const loginApi = createAsyncThunk(
    "login/authlogin",
    async (userDetails) => {
        try {
            const response = await BaseUrl.post(`signin`, userDetails);
            // console.log("response.data", response.data)
            return response.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getAllUserdata = createAsyncThunk(
    "users/getAllUserdata",
    async () => {
        try {
            const response = await BaseUrl.get(`user`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getAllUserListdata = createAsyncThunk(
    "users/getAllUserListdata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`user/companylist/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getAllPlantUserListdata = createAsyncThunk(
    "users/getAllPlantUserListdata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`user/plantlist/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getAllMachineUserListdata = createAsyncThunk(
    "users/getAllMachineUserListdata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`user/machinelist/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getSingleUserdata = createAsyncThunk(
    "users/getSingleUserdata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`user/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const oilManufacturerUserdata = createAsyncThunk(
    "users/oilManufacturerUserdata",
    async () => {
        try {
            const response = await BaseUrl.get(`user/oilmanufacturelist`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const oilVainderUserdata = createAsyncThunk(
    "users/oilVainderUserdata",
    async () => {
        try {
            const response = await BaseUrl.get(`user/oilvendorlist`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const machineUserdata = createAsyncThunk(
    "users/machineUserdata",
    async () => {
        try {
            const response = await BaseUrl.get(`user/machineadminlist`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const createUserdata = createAsyncThunk(
    "users/createUserdata",
    async (data) => {
        try {
            const response = await BaseUrl.post(`signup`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const updateeUserdata = createAsyncThunk(
    "users/updateeUserdata",
    async (data) => {
        try {
            const response = await BaseUrl.patch(`user`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

// login SLICE
export const loginSlice = createSlice({
    name: "auth",
    initialState: {
        userData: [],
        userList: [],
        plantUserList: [],
        machineUserList: [],
        oilVainderAdminUser: [],
        oilManufacturerAdminUser: [],
        machineAdminUser: [],
        singleUserData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllUserdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllUserdata.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(getAllUserdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getAllUserListdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllUserListdata.fulfilled, (state, action) => {
                state.loading = false;
                state.userList = action.payload;
            })
            .addCase(getAllUserListdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getAllPlantUserListdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllPlantUserListdata.fulfilled, (state, action) => {
                state.loading = false;
                state.plantUserList = action.payload;
            })
            .addCase(getAllPlantUserListdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getAllMachineUserListdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllMachineUserListdata.fulfilled, (state, action) => {
                state.loading = false;
                state.machineUserList = action.payload;
            })
            .addCase(getAllMachineUserListdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getSingleUserdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleUserdata.fulfilled, (state, action) => {
                state.loading = false;
                state.singleUserData = action.payload;
            })
            .addCase(getSingleUserdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(oilManufacturerUserdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(oilManufacturerUserdata.fulfilled, (state, action) => {
                state.loading = false;
                state.oilManufacturerAdminUser = action.payload;
            })
            .addCase(oilManufacturerUserdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(oilVainderUserdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(oilVainderUserdata.fulfilled, (state, action) => {
                state.loading = false;
                state.oilVainderAdminUser = action.payload;
            })
            .addCase(oilVainderUserdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(machineUserdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(machineUserdata.fulfilled, (state, action) => {
                state.loading = false;
                state.machineAdminUser = action.payload;
            })
            .addCase(machineUserdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});

// SELECTORS
export const getUsersData = (state) => state.auth.userData;
export const getUsersListData = (state) => state.auth.userList;
export const getplantUserListData = (state) => state.auth.plantUserList;
export const getmachineUserListData = (state) => state.auth.machineUserList;
export const getOilManufacturerAdminUser = (state) => state.auth.oilManufacturerAdminUser;
export const getoilVainderAdminUserUser = (state) => state.auth.oilVainderAdminUser;
export const getmachineAdminUser = (state) => state.auth.machineAdminUser;
export const getSingleUsersData = (state) => state.auth.singleUserData;
export const loginerror = (state) => state.auth.error;

// EXPORTS
export default loginSlice.reducer;